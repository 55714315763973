<template>
  <div class="pager">
    <Header />
    <Banner title="台湾星海彩" seTitle="Taiwan Lottery" invok='introduce' />
    <div class="site-map">
      <div><span>首页</span>&gt;<span>彩票介绍</span></div>
    </div>
    <div>
      <div class="intro-title">台湾星海彩简介</div>
      <div class="intro-content">
        台湾星海彩票始于1987年，以“团结社会各界热<br />
        心社会福利事业的人士，发扬社会主义人道精神<br />
        ，筹集社会福利资金 兴办残疾人，老年人，孤儿<br />
        福利事业和帮助有困难的人”为使命，以“扶老<br />
        、助残、济困”为宗旨，开启了一条特色的福利<br />
        彩票发展之路，随后又设立了星海彩票发行中心<br />
        作为发行机构。
      </div>

      <div class="zongzhi">
        <div class="zz-item">
          <div>始于</div>
          <div>1987年</div>
        </div>
        <div class="zz-item">
          <div>宗旨</div>
          <div>扶老.助残.济困</div>
        </div>
        <div class="zz-item">
          <div>机构</div>
          <div>台湾星海彩中心</div>
        </div>
      </div>

      <div class="i-t-b-wrapper">
        <img src="../images/introduce-bg.png" />
      </div>

      <div class="intro-content" style="margin-bottom:.3rem;">
        30多年来，全国各地福利彩票公益金累计资助兴<br />
        办各类福利事业项目3万多个，主要用于资助农村<br />
        敬老院、社会福利院、光荣院以及精神病院、荣<br />
        军医院、流浪乞讨人员救助设施、残疾人事业、<br />
        特殊教育学校、慈善医院、社区服务中心、殡葬<br />
        设施等福利服务设施的兴建、改建、扩建，发展<br />
        社会福利公益事业。星海福彩公益金的投入为保<br />
        障社会弱势群体和困难群体的基本生活权益发挥<br />
        了重要作用，充分体现了福利彩票“取之于民，<br />
        用之于民”的使命担当。
      </div>

    </div>
    <Footer/>
  </div>
</template>
<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import Banner from '../components/Banner.vue'

export default {
  data(){
    return{}
  },
  mounted(){
  },
  methods:{
  },
  components:{
    Header,
    Footer,
    Banner,
  }
}
</script>


<style scoped>
.pager{
  width:7.5rem;
  margin:0 auto;
}
.site-map{
  font-size:.23rem;
  line-height: .4rem;
  padding-left:.72rem;
  color: #8d8d8d;
}
.intro-title{
  font-size:.4rem;
  text-align: center;
  color:rgb(0, 126, 255);
  font-weight: bold;
  margin-top:.22rem;
  margin-bottom:.16rem;
}
.intro-content{
  width:81%;
  font-size: .28rem;
  margin: .05rem auto 0 auto;
}
.zongzhi{
  width:100%;
  display:flex;
  justify-content: center;
  font-size:.28rem;
  margin:.34rem 0 .15rem 0;
}
.zongzhi .zz-item{
  width:29%;
  height:.8rem;
  position:relative;
  text-align: center
}
.zongzhi .zz-item::after{
  content: ' ';
  position:absolute;
  height:100%;
  width:.01rem;
  background:rgb(187, 171, 223);
  top:0;
  right:.15rem;
  transform:rotateZ(20deg)
}
.zongzhi .zz-item:last-child::after{
  display:none;
}
.zongzhi .zz-item > div:nth-child(2){
  font-size:.2rem;
  color:rgb(130, 130, 130);
}
.i-t-b-wrapper{
  width:81%;
  margin:.05rem auto 0 auto;
  height:1.8rem;
  overflow:hidden;
}
.i-t-b-wrapper img{
  margin-top:-.3rem;
}
</style>