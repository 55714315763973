<template>
  <div class="wrapper">
    <img src="../images/logo-dark.png" class="logo" />
    <div class="menu"></div>
  </div>
</template>

<style scoped>
  .wrapper{
    width:7.5rem;
    height:1.38rem;
    padding: 0 .3rem;
    display:flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo{
    width:2.62rem;
  }
  .menu{
    width:.42rem;
    height:.32rem;
    border: .04rem solid black;
    border-left: none;
    border-right: none;
    display: flex;
  }
  .menu::after{
    content:' ';
    display:block;
    width:100%;
    height:.04rem;
    background:#000;
    margin:auto;
  }
</style>