<template>
  <div class="main">
    <div class="banner">
      <div class="tool">
        <img class="logo" src="../images/logo.png" />
        <img class="menu-icon" src="../images/menu-icon.png" />
      </div>
      <div class="effect-l-title">
        <img src="../images/lotitle.png" />
      </div>
      <div class="challenge">
        challenge your luck
      </div>
      <div class="l-tru-wrapper">
        <div class="l-t-inner-w">
          <div class="t-i-wrapper">
            <div>
              第<span class="p-important">219</span>期
            </div>
            <div>
              本期开奖时间：2023-08-28 20:10
            </div>
            <div class="bonus-link-w" @click="bonusRecordClick">
              [开奖记录]
            </div>
          </div>

          <div class="bonus-i-wrapper">
            <div style="margin-right:-.17rem;">
              <div class="b-i-mtitle">
                平码
              </div>
              <div class="b-i-xtitle">
                平肖
              </div>
            </div>
            <div v-for="bonus of bonusCodeList">
              <div class="bonus-img" :class="bonus.styleColor">
                <div class="b-i-w-c">
                  {{bonus.code}}
                </div>
              </div>
              <div class="b-i-zodiac">
                {{bonus.zodiac}}
              </div>
            </div>
            <div>
              <div class="plus-w">
                +
              </div>
              <div>
              </div>
            </div>
            <div>
              <div class="bonus-img" :class="tailObj.styleColor">
                <div class="b-i-w-c">
                  {{tailObj.code}}
                </div>
              </div>
              <div class="b-i-zodiac">
                {{tailObj.zodiac}}
              </div>
            </div>
          </div>

          <div class="next-date-time">
            下期开奖时间：2023-08-29&nbsp;20:10
          </div>

        </div>
      </div>
    </div>

    <div class="announcement">
      <div class="ann-wrap">
        公告
      </div>
      <div class="ann-icon-wrapper"></div>
      <div class="ann-content">
        尊敬的用户，台湾星海彩官方网站主线路为:<a href="http://www.twxhcp8.com" target="_blank">www.twxhcp8.com</a>
      </div>
    </div>

    <div class="picture">
      <div class="title1">
        Taiwan pictures
      </div>
      <div class="title2">
        正版图库
      </div>
      <div class="title3">
        台湾星海彩 绝密图报
      </div>
      <div class="inner-pic-wrapper">
        <div class="i-p-item"></div>
        <div class="i-p-item"></div>
      </div>
      <div style='text-align:right;padding-right:.83rem;'>
        <span class="pic-more-link" @click="picMoreClick">更多&gt;</span>
      </div>
    </div>

    <div class="introduce">
      <div class="title">
        彩票介绍
      </div>
      <div class="title2">
        台湾星海彩
      </div>
      <div class="content">
        台湾星海彩票始于1987年以扶老、助残、济困为宗旨，开后了一条特色的福利彩票发展之路，随后又设立了星海彩票发行中心作为发行机构.
      </div>

      <div class="time">
        <div>
          <div>始于</div>
          <div>1987年</div>
        </div>
        <div>
          <div>宗旨</div>
          <div>扶老.助残.济困</div>
        </div>
        <div>
          <div>机构</div>
          <div>台湾星海彩中心</div>
        </div>
      </div>

      <div class="more" @click="introduceMoreClick">
        <img src="../images/more-btn.png" />
      </div>
    </div>

    <div class="news">
      <div class="n-t-wrapper">
        <div class="news-title">NEWS</div>
        <div class="news-title1">新闻动态</div>
      </div>
      <div class="secondary">
        <div class="n-s-pic-wrapper">
          <div class="n-s-pic-item">
          </div>
          <div class="n-s-pic-item">
          </div>
        </div>

        <div class="news-title-wrapper">
          <div>
            <div class="deep-title">通膨疑虑挥之不去！</div>
            <div class="fresh-title">美股道指早盘跌150点</div>
            <div class="fresh-title">台积电ADR跌逾2%</div>
            <div class="time">2024-03-15</div>
          </div>
          <div>
            <div class="deep-title">3/15大乐透龙年</div>
            <div class="fresh-title">今彩539头奖800万</div>
            <div class="fresh-title">南投1注独得</div>
            <div class="time">2024-03-15</div>
          </div>
        </div>

        <div class="hot-news-wrapper">
          <div class="hot-n-item">
            <div>热门资讯</div>
            <div @click="newsMoreClick">更多&gt;</div>
          </div>
          <div class="hot-n-item" v-for="no of 3">
            <div class="h-n-i-pic">
              图片
            </div>
            <div class="h-n-c-w">
              <div class="news-c-w">中国渔船金门东定海域沉没 两岸持续关注打开抵扣税款的</div>
              <div style="padding-right:.22rem;">2024-03-15</div>
            </div>
          </div>
        </div>

      </div>

    </div>

    <div class="real-time">
      <div class="dynamic">实时动态更新</div>
      <div class="bonus-ann">开奖公告</div>
      <div class="bonus-t-wrapper">
        <div class="t-title">
          <div>期数</div>
          <div>开奖号</div>
          <div>星期</div>
        </div>
        <div class="t-d-wrapper">
          <div v-for="no of 10" class="t-d-w-row">
            <div class="no">031</div>
            <div class="n-bet">
              <div class="bonus-img" :class="no%3 == 0 ? 'red':no%3 == 1 ? 'green':'blue'" v-for="no of 6">
                <div class="b-i-w-c">02</div>
              </div>
              <div class="b-i-w-plus">
                +
              </div>
              <div class="bonus-img red" >
                <div class="b-i-w-c">02</div>
              </div>
            </div>
            <div class="week">星期一</div>
          </div>
        </div>

        <div class="real-time-more">
          了解更多&gt;
        </div>

      </div>
    </div>

    <div class="five-element">
      <div>The five elements</div>
      <div class="five-no-dz">五行号码对照</div>
      <div>|2024年|</div>
      <div class="xsxk">
        <div class="wx-item-w">
          <div class="xsxk-title">
            <img class="xsxk-t-img" src="../images/sxxs.png" />
            <div>
              五行相生
            </div>
          </div>
          <div class="xsxk-item-row">
            【金生水】→【水生木】
          </div>
          <div class="xsxk-item-row">
            【木生火】→【火生土】
          </div>
          <div class="xsxk-item-row">
            【土生金】
          </div>
        </div>
        <div class="wx-item-w">
          <div class="xsxk-title">
            <img class="xsxk-t-img" src="../images/sxxk.png" />
            <div>
              五行相克
            </div>
          </div>
          <div class="xsxk-item-row">
            【金克木】→【木克土】
          </div>
          <div class="xsxk-item-row">
            【土克水】→【水克火】
          </div>
          <div class="xsxk-item-row">
            【火克金】
          </div>
        </div>
      </div>

      <div class="five-ele-graphic">
        <div class="f-e-i-wrapper">
          <div class="se-e-graphic">
            <div class="se-i-graphic">
              <div class="t-i-graphic"></div>
            </div>
          </div>
        </div>

        <div class="five-i-c-w">
          <div class="five-t-wrapper">
            <div>
              土
            </div>
            <img src="../images/ele-ico-earth.png" alt="">
          </div>

          <div class="bet-code">
            04 05 18 19 26 27 34 35 48 49
          </div>

        </div>
        <div class="five-i-c-w metal">
          <div class="five-t-wrapper">
            <div>
              金
            </div>
            <img src="../images/ele-ico-metal.png" alt="">
          </div>

          <div class="bet-code">
            02 03 10 11 24 25 32 33 40 41
          </div>

        </div>
        <div class="five-i-c-w wood">
          <div class="five-t-wrapper">
            <div>
              木
            </div>
            <img src="../images/ele-ico-wood.png" alt="">
          </div>

          <div class="bet-code">
            06 07 14 15 22 23 36 37 44 45
          </div>

        </div>
        <div class="five-i-c-w water">
          <div class="five-t-wrapper">
            <div>
              水
            </div>
            <img src="../images/ele-ico-water.png" alt="">
          </div>
          <div class="bet-code">
            12 13 20 21 28 29 42 43
          </div>
        </div>
        <div class="five-i-c-w fire">
          <div class="five-t-wrapper">
            <div>
              火
            </div>
            <img src="../images/ele-ico-fire.png" alt="">
          </div>
          <div class="bet-code">
            01 08 09 16 17 30 31 38 39 46 47
          </div>
        </div>

        <div class="moon m1"></div>
        <div class="moon m2"></div>

      </div>
    </div>

    <div class="zodiacs-duizhao">
      <div class='block-title'>
        The 12 Chinese Zodiacs
      </div>
      <div class='block-title t1'>
        生肖对照
      </div>
      <div class='block-title t2'>
        |2024年|
      </div>

      <div class="zodiac-list-wrapper">
        <div class="zodiac-code-wrapper">
          <div>
            <img class="zodiac-item-pic" src="../images/zodiac-pic-dragon.png" alt="">
          </div>
          <div class="zodiac-word">龙</div>
          <div class="z-b-c-wrapper">
            <div class="zodiac-bet-code red">10</div>
            <div class="zodiac-bet-code red">13</div>
            <div class="zodiac-bet-code blue">25</div>
            <div class="zodiac-bet-code blue">27</div>
            <div class="zodiac-bet-code green">49</div>
          </div>
        </div>

        <div class="zodiac-code-wrapper">
          <div>
            <img class="zodiac-item-pic" src="../images/zodiac-pic-robbit.png" alt="">
          </div>
          <div class="zodiac-word">兔</div>
          <div class="z-b-c-wrapper">
            <div class="zodiac-bet-code red">02</div>
            <div class="zodiac-bet-code blue">14</div>
            <div class="zodiac-bet-code blue">26</div>
            <div class="zodiac-bet-code green">38</div>
          </div>
        </div>

        <div class="zodiac-code-wrapper">
          <div>
            <img class="zodiac-item-pic" src="../images/zodiac-pic-tigger.png" alt="">
          </div>
          <div class="zodiac-word">虎</div>
          <div class="z-b-c-wrapper">
            <div class="zodiac-bet-code blue">03</div>
            <div class="zodiac-bet-code blue">15</div>
            <div class="zodiac-bet-code green">27</div>
            <div class="zodiac-bet-code green">39</div>
          </div>
        </div>

        <div class="zodiac-code-wrapper">
          <div>
            <img class="zodiac-item-pic" src="../images/zodiac-pic-bull.png" alt="">
          </div>
          <div class="zodiac-word">牛</div>
          <div class="z-b-c-wrapper">
            <div class="zodiac-bet-code blue">04</div>
            <div class="zodiac-bet-code green">16</div>
            <div class="zodiac-bet-code green">28</div>
            <div class="zodiac-bet-code red">40</div>
          </div>
        </div>

      </div>

    </div>
    
    <div class="red-blue-green-w">
      <div>
        Red blue green
      </div>
      <div class="important-title">
        2024年波色对照表
      </div>
      <div class="red-blue-green-middle-title">
        |红波.蓝波.绿波|
      </div>
      <div class="wave-row-item red">
        <div>
          红波
        </div>
        <div class="w-r-i-bet">
          1.2.7.8.12.13.18.19.23.24<br />
          29.30.34.35.40.45.46
        </div>
      </div>

      <div class="wave-row-item blue">
        <div>
          蓝波
        </div>
        <div class="w-r-i-bet">
          3.4.9.10.14.15.20.25.26.31<br />
          36.37.41.42.47.48
        </div>
      </div>

      <div class="wave-row-item green">
        <div>
          绿波
        </div>
        <div class="w-r-i-bet">
          5.6.11.16.17.21.22.27.28<br />
          32.33.38.39.43.44.49
        </div>
      </div>

    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from '../components/Footer.vue'
export default {
  name: 'HomeView',
  data(){
    return {
      bonusCodeList:[
        {
          code:'02',
          zodiac:'鼠',
          styleColor:'red'
        },
        {
          code:'45',
          zodiac:'牛',
          styleColor:'red'
        },
        {
          code:'28',
          zodiac:'虎',
          styleColor:'green'
        },
        {
          code:'10',
          zodiac:'蛇',
          styleColor:'blue'
        },
        {
          code:'19',
          zodiac:'马',
          styleColor:'red'
        },
        {
          code:'24',
          zodiac:'兔',
          styleColor:'red'
        }
      ],
      tailObj:{
        code:'37',
        zodiac:'豬',
        styleColor:'blue'
      },
      
    }
  },
  methods:{
    bonusRecordClick(){
      this.$router.push('/record')
    },
    picMoreClick(){
      this.$router.push('/gallery')
    },
    introduceMoreClick(){
      this.$router.push('/introduce')
    },
    newsMoreClick(){
      this.$router.push('/hotnews')
    }
  },
  components: {
    Footer
  }
}
</script>

<style scoped>
.main{
  width:7.5rem;
  margin:0 auto;
}
.main .banner{
  width:100%;
  height:8.01rem;
  background-image:url(../images/banner-bg.png);
  background-size:cover;
  position:relative;
}
.main .banner .tool{
  width:100%;
  display:flex;
  align-items: center;
  padding:.6rem .42rem 0 1.43rem;
  justify-content:space-between;
}
.main .banner .tool .logo{
  width:2.34rem;
}
.main .banner .tool .menu-icon{
  width:.5rem;
}
.effect-l-title{
  width:100%;
  text-align: center;
  margin-top:1.32rem;
}
.effect-l-title img{
  width:4.89rem;
}
.challenge{
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size:.3rem;
  margin-top: -.09rem;
}
.l-tru-wrapper{
  background-color:#EAEBEB;
  position:absolute;
  bottom:.26rem;
  left:50%;
  transform:translateX(-50%);
  border-radius:.05rem;
  width: 90%;
  height: 1.93rem;
  display:flex;
}

.l-t-inner-w{
  width: 98%;
  height: 93%;
  border-radius: 0.06rem;
  margin:auto;
  background-color:white;
}
.t-i-wrapper{
  width:100%;
  height:.48rem;
  display:flex;
  justify-content: space-between;
  font-size:.16rem;
  color:#8D8D8D;
  padding: .1rem .16rem 0 .16rem;
}
.bonus-i-wrapper{
  width: 95%;
  margin:auto;
  display:flex;
  justify-content: space-around;
  border:1px solid #575757;
  border-top:none;
}
.next-date-time{
  width:100%;
  text-align: center;
  color:#8D8D8D;
  margin-top:.14rem;
}
.b-i-mtitle,.b-i-xtitle{
  display:flex;
  justify-content: center;
  align-items: center;
  height:.39rem;
  text-shadow: 0 0 3px #6f6f6f;
  font-size: .16rem;
}
.bonus-img{
  width: .49rem;
  height: .46rem;
  display: flex;
  border-radius: 50%;
  border: .02rem solid white;
  box-shadow: 0 0 .05rem 0 rgba(0,0,0,.4);
  background-size: 100% 100%;
}
.bonus-img.red{
  background-image:url(../images/n-bg-red.png);
}
.bonus-img.green{
  background-image:url(../images/n-bg-green.png);
}
.bonus-img.blue{
  background-image:url(../images/n-bg-blue.png);
}
.b-i-zodiac{
  width:100%;
  text-align: center;
  text-shadow:0 0 3px #6f6f6f;
  font-size:.2rem;
}
.plus-w{
  font-size:.37rem;
  font-weight: bold;
}
.b-i-w-c{
  margin:auto;
  font-size:.18rem;
  font-weight: bold;
}
.bonus-link-w{
  cursor: pointer;
  color:#2392D9;
}
.p-important{
  color:#FE0049;
}

.announcement{
  width:90%;
  height:.6rem;
  margin:.2rem auto;
  border-radius:.1rem;
  background-color:#FAFAFA;
  display:flex;
  align-items: center;
}
.ann-wrap{
  border: solid 2px;
  border-radius:4px;
  width: .51rem;
  height: .33rem;
  color: #BAA27E;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .16rem;
}
.ann-icon-wrapper{
  width:.42rem;
  height:.28rem;
  background-image:url(../images/ann-icon.png);
  background-size:100% 100%;
}
.ann-content{
  font-size:.18rem;
  color:#CE2413;
}
.ann-content a{
  color:#CE2413;
  text-decoration: none;
}
.picture{
  width:100%;
  height:8.44rem;
  background-image: url(../images/picture-bg.png);
  background-size:100% 100%;
}
.picture > div{
  text-align: center;
  font-size:.28rem;
}
.picture .title1{
  color:#000000;
  padding-top:.2rem;
}
.picture .title2{
  color:#076763;
  font-weight: bold;
  margin:.18rem 0;
}
.picture .title3{
  color:#353232;
}
.picture .inner-pic-wrapper{
  height:5.46rem;
  margin:.1rem 0;
  display:flex;
  justify-content:center;
  align-items: center;
}
.picture .inner-pic-wrapper .i-p-item{
  width:2.54rem;
  height:78%;
  background:white;
  box-shadow:0 0 10px 0 rgba(0,0,0,.5)
}
.picture .inner-pic-wrapper .i-p-item:first-child{
  margin-right:.64rem;
}

.pic-more-link{
  cursor: pointer;
}

.introduce{
  text-align: center;
  padding-top:.45rem;
  padding-bottom:.88rem;
  background-image:url(../images/introduce-bg.png);
  background-repeat: no-repeat;
  background-position: left 2.61rem;
  background-size:contain;
}
.introduce .title{
  font-size:.3rem;
  font-weight: bold;
}
.introduce .title2{
  font-size:.26rem;
  color:#8E8E8E;
  margin:.26rem 0 .65rem 0;
}
.introduce .content{
  color:#7f7f7f;
  font-size: .22rem;
  padding:0 .9rem;
  font-weight: bold;
  text-align: left;
  line-height:.39rem;
}
.introduce .time{
  width:6.66rem;
  height:1.56rem;
  background:white;
  border-radius:.1rem;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.5);
  margin:.42rem auto 0 auto;
  display:flex;
  align-items: center;
  font-size:.3rem;
  font-weight: bold;
}

.introduce .time > div{
  width:33.33%;
  height:87%;
  text-align: center;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right:1px solid #7E63C4;
}
.introduce .time > div:first-child{
  width:24%;
}
.introduce .time > div:nth-child(2){
  width:36%;
}
.introduce .time > div:nth-child(3){
  width:39.8%;
  border-right:none;
}
.introduce .time > div > div:last-child{
  color:#A8A5FC;
  margin-top:.08rem;
}
.introduce .more{
  border-radius:.34rem;
  border: solid #8062C4 .06rem;
  width:2.68rem;
  padding:.04rem 0;
  margin:.9rem auto 0 auto;
  box-shadow:0 0 10px 0 rgba(0,0,0,.4);
  background:white;
}
.introduce .more img{
  width:2.02rem;
  border-radius:5px;
}
.news{
  width:100%;
  text-align: center;
  background:#F4F8FB;
  padding-top:1.06rem;
}
.news .news-title{
  color:#000000;
  font-size:.4rem;
}
.news .news-title1{
  color:#02538A;
  font-weight:bold;
  font-size:.4rem;
}
.news .n-t-wrapper{
  width:100%;
  height:3.5rem;
}
.news .secondary{
  width:100%;
  height:auto;
  background-color:white;
  position:relative;
  padding-top:1.16rem;
}
.n-s-pic-wrapper{
  width:100%;
  position:absolute;
  display:flex;
  justify-content: center;
  top:-2rem;
  left:50%;
  transform: translateX(-50%);
}
.n-s-pic-item{
  width:3.12rem;
  height:3.18rem;
  background-color:black;
  border:.15rem solid white;
}
.n-s-pic-item:first-child{
  border-right:none;
  margin-right:.2rem;
}
.n-s-pic-item:last-child{
  border-left:none;
}

.news-title-wrapper{
  width:100%;
  display:flex;
  font-size:.26rem;
  padding-left:.66rem;
  padding-top:.26rem;
  font-weight:bold;
  padding-bottom:.58rem;
}
.news-title-wrapper .deep-title{
  font-size:.3rem;
  margin-bottom:.24rem;
}
.news-title-wrapper .fresh-title{
  color:#727272;
  margin-bottom:.1rem;
}
.news-title-wrapper .time{
  margin-top:.4rem;
  font-size:.24rem;
  color:#B8B8B8;
}
.news-title-wrapper > div{
  width:3.23rem;
  padding-left:.22rem;
  text-align: left;
}

.hot-news-wrapper{
  width:100%;
  padding-bottom:.22rem;
}
.hot-news-wrapper .hot-n-item{
  width:80%;
  margin:auto;
  display: flex;
  justify-content: space-between;
  border-bottom:dashed 1px gray;
  font-size:.2rem;
  padding-bottom:.14rem;
}
.hot-news-wrapper .hot-n-item:first-child{
  font-size:.28rem;
  border-bottom:none;
}
.hot-news-wrapper .hot-n-item:last-child{
  border-bottom:none;
}
.hot-news-wrapper .hot-n-item .h-n-c-w{
  text-align: right;
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  color:#909090;
}
.hot-news-wrapper .hot-n-item .news-c-w{
  width:4.58rem;
  text-overflow: ellipsis;
  white-space:nowrap;
  overflow:hidden;
}
.hot-news-wrapper .hot-n-item .h-n-i-pic{
  width:1.34rem;
  height:1.4rem;
  display:flex;
  justify-content: center;
  align-items: center;
}
.real-time{
  background:#EBEBEB;
  text-align: center;
  padding-top:.4rem;
}
.real-time .bonus-ann{
  color:#01548A;
  font-size:.3rem;
  font-weight:700;
}
.real-time .dynamic{
  font-size:.3rem;
  font-weight:900;
}
.real-time .bonus-t-wrapper{
  margin:.3rem auto 0 auto;
  width:88%;
  padding-bottom:.72rem;
}
.bonus-t-wrapper .t-title{
  display:flex;
  color:#01548A;
  font-size:.2rem;
  margin-bottom:.1rem;
}
.bonus-t-wrapper .t-title > div:nth-child(1){
  width:20%;
}
.bonus-t-wrapper .t-title > div:nth-child(2){
  width:55%;
}
.bonus-t-wrapper .t-title > div:nth-child(3){
  width:25%;
}
.bonus-t-wrapper .t-d-wrapper{
  width:100%;
  background-color:white;
  color:#00548A;
}
.t-d-w-row{
  display:flex;
  border-bottom:1px solid #D9D9D9;
  font-size:.17rem;
}
.t-d-w-row .no{
  width:20%;
}
.t-d-w-row .n-bet{
  width:60%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.t-d-w-row .week{
  width:20%;
}
.t-d-w-row > div{
  border-right:1px solid #D9D9D9;
  height:.48rem;
  line-height:.48rem;
}
.t-d-w-row > div:last-child{
  border-right: none;
}
.n-bet .bonus-img{
  width: .44rem;
  height: .42rem;
  display: flex;
  border-radius: 50%;
  border:none;
  box-shadow:none;
  background-size: 100% 100%;
  line-height: 1;
}
.n-bet .b-i-w-plus{
  font-size:.26rem;
  font-weight: bold;
  color: #AEAEAE;
}

.real-time-more{
  color: #01548A;
  border: 1px solid #B195AC;
  border-radius:.27rem;
  width:1.54rem;
  line-height:.43rem;
  margin:.33rem auto 0 auto;
  font-size:.22rem;
}

.five-element{
  background:#E8F5FD;
  text-align: center;
  font-size:.26rem;
  padding-top:.3rem;
  padding-bottom:.2rem;
}
.five-element .five-no-dz{
  color:#076763;
  font-weight: bold;
}
.five-element .xsxk{
  display:flex;
  justify-content:center;
  margin-top:.32rem;
}
.xsxk .wx-item-w{
  width:3.38rem;
  height:2.6rem;
  background:white;
  color:#8E8E8E;
  font-size:.21rem;
  font-weight: bold;
}
.xsxk .wx-item-w:first-child{
  margin-right:.2rem;
}
.xsxk .xsxk-title{
  color:#000000;
  font-weight: bold;
  font-size:.3rem;
  display:flex;
  align-items: center;
  justify-content: center;
  padding-top:.32rem;
  margin-bottom:.12rem;
}
.xsxk .xsxk-title .xsxk-t-img{
  width:.68rem;
  margin-right:.18rem;
}

.xsxk .xsxk-item-row{
  margin-bottom:.16rem;
}

.five-ele-graphic{
  background:linear-gradient(#00FE12,#3EAE1C);
  width:6rem;
  height:6rem;
  margin:.68rem auto 0 auto;
  border-radius:50%;
  display:flex;
  position:relative;
}
.five-ele-graphic .f-e-i-wrapper{
  margin:auto;
  border-radius: 50%;
  width: 98.8%;
  height: 98.8%;
  background:#E8F5FD;
  display:flex;
}
.f-e-i-wrapper .se-e-graphic{
  width:60%;
  height:60%;
  display:flex;
  margin:auto;
  border-radius: 50%;
  background:linear-gradient(#00FE12,#3EAE1C);
}
.f-e-i-wrapper .se-e-graphic .se-i-graphic{
  border-radius: 50%;
  width:98%;
  height:98%;
  background:#E8F5FD;
  margin:auto;
  display:flex;
}

.f-e-i-wrapper .se-e-graphic .se-i-graphic .t-i-graphic{
  width:18%;
  height:18%;
  background:url(../images/five-center-g.png);
  background-size:100% 100%;
  margin:auto;
  border-radius: 50%;
}

.five-ele-graphic .five-i-c-w{
  width: 1.38rem;
  height: 1.38rem;
  border-radius: 50%;
  box-shadow: 0 0 .1rem 0 rgba(0,0,0,.2);
  position: absolute;
  background-color: white;
  left: -.23rem;
  top:1.08rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.five-i-c-w.metal{
  left:2.28rem;
  top:.62rem;
}
.five-i-c-w.wood{
  left:4.8rem;
  top:.53rem;
}
.five-i-c-w.water{
  left: 1.24rem;
  top: 3.71rem;
}
.five-i-c-w.fire{
  left:3.89rem;
  top:2.93rem;
}

.five-ele-graphic .moon{
  width:.3rem;
  height:.3rem;
  background:white;
  border-radius:50%;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
  position:absolute;
}
.five-ele-graphic .moon.m1{
  left: -.05rem;
  top: 3.53rem;
}
.five-ele-graphic .moon.m2{
  left:4.15rem;
  top:.15rem;
}
.five-i-c-w .five-t-wrapper{
  display:flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.five-t-wrapper img{
  width:.38rem;
}
.five-i-c-w .bet-code{
  width:81%;
  overflow: hidden;
  white-space: wrap;
  font-size:.18rem;
  line-height:.19rem;
  margin-top:.08rem;
  font-weight: bold;
}
.zodiacs-duizhao{
  width:100%;
  background-color:#EBEBEB;
  padding-top:.4rem;
  padding-bottom:.4rem;
}

.zodiacs-duizhao .block-title{
  font-size:.24rem;
  text-align: center;
}
.zodiacs-duizhao .block-title.t1{
  color:#086763;
  font-weight:bold;
}
.zodiac-list-wrapper{
  display:flex;
  margin-top:.32rem;
}
.zodiac-list-wrapper .zodiac-code-wrapper{
  background:white;
  width:1.68rem;
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-left:.15rem;
  padding-top:.06rem;
  padding-bottom:.1rem;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
}
.zodiac-list-wrapper .zodiac-code-wrapper .zodiac-item-pic{
  height:.98rem;
}
.zodiac-list-wrapper .zodiac-code-wrapper .zodiac-word{
  font-size:.4rem;
}
.zodiac-list-wrapper .zodiac-code-wrapper .zodiac-bet-code{
  width:.48rem;
  height:.48rem;
  background-size:100% 100%;
  border-radius:50%;
  font-size:.28rem;
  display:flex;
  justify-content: center;
  align-items: center;
  color:white;
  font-weight: bold;
}
.zodiac-bet-code.red{
  background-image:url(../images/bet-code-red.png);
}
.zodiac-bet-code.green{
  background-image:url(../images/bet-code-green.png);
}
.zodiac-bet-code.blue{
  background-image:url(../images/bet-code-blue.png);
}
.z-b-c-wrapper{
  width:100%;
  height:2.45rem;
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.red-blue-green-w{
  background-color:#E8F5FD;
  text-align: center;
  font-size:.24rem;
  padding-top:.36rem;
  padding-bottom:.01rem;
}
.important-title{
  color: #086763;
  font-weight:bold;
}
.red-blue-green-middle-title{
  margin-bottom:.26rem;
}
.wave-row-item{
  display:flex;
  width: 92%;
  margin: auto;
  color:white;
  border-radius:.1rem;
  height:1.06rem;
  margin: 0 auto .14rem auto;
  align-items: center;
  padding-left:.2rem;
}
.wave-row-item.red{
  background:#FC0204;
}
.wave-row-item.green{
  background:#027F01;
}
.wave-row-item.blue{
  background:#0602ED;
}
.wave-row-item .w-r-i-bet{
  font-size:.3rem;
  text-align: left;
  border-left:.03rem solid;
  margin-left:.36rem;
  padding-left:.32rem;
}
.wave-row-item > div:first-child{
  font-size:.36rem;
  font-weight: bold;
}


</style>