<template>
  <router-view/>
</template>

<script>
export default {
  data(){
    return{}
  },
  mounted(){
    window.addEventListener('resize',this.layout);
    this.layout();
  },
  methods:{
    layout(){
      const sWidth = 750,sRatio = 100,maxWidth = 1008;
      let calWidth = innerWidth > maxWidth ? maxWidth:innerWidth;
      let fontSize = calWidth * sRatio / sWidth;
      document.querySelector('html').style.fontSize = `${fontSize}px`;
    }
  }
}
</script>

<style>
*{
  box-sizing: border-box;
}
html,body{
  margin:0;
  padding:0;
  scroll-behavior:smooth;
}

body{
  font-size:.14rem;
}
</style>
