<template>
  <div class="pager">
    <Header />
    <Banner title="台湾星海彩" seTitle="Taiwan Lottery" />
    <div class="year-list-w">
      <div class="y-l-item" :class="{curr:yearIndex == i}" v-for="(yItem,i) of yearList" @click="yearTabClick(i)">
        {{yItem.year}}年
      </div>
    </div>

    <div class="history-title">
      历史记录
    </div>
    <div class="history-head history-row">
      <div class="date-time">开奖时间</div>
      <div>期数</div>
      <div class="zhengma" style="padding-top:0;">正码</div>
      <div class="tema">特码</div>
      <div>生肖</div>
      <div>波色</div>
    </div>
    <div class="history-row" v-for="hItem of historyDataList">
      <div class="date-time">{{hItem.dateTime}}</div>
      <div>{{hItem.dateNum}}</div>
      <div class="zhengma" style="display:flex;">
        <div v-for="zodiacCode of hItem.codeList">
          <div class="bonus-code red">{{zodiacCode.code}}</div>
          <div class="bonus-zodiac">{{zodiacCode.zodiac}}</div>
        </div>
      </div>
      <div class="tema">
        <div class="data-ceil">
          <div class="bonus-code green">
            <div class="plus-flag">
              +
            </div>
            {{hItem.specialNo.code}}
          </div>
          <div class="bonus-zodiac">
            {{hItem.specialNo.zodiac}}
          </div>
        </div>
      </div>
      <div>{{hItem.zodiac}}</div>
      <div>{{hItem.ballColor}}</div>
    </div>

    <div style="height:.6rem;">
      分页组件
    </div>
    <Footer/>
  </div>
</template>
<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import Banner from '../components/Banner.vue'

export default {
  data(){
    return{
      yearList:[
        {
          year:2024,
        },
        {
          year:2023
        },
        {
          year:2022
        }
      ],
      yearIndex:0,
      historyDataList:[],
    }
  },
  mounted(){
    this.genData();
  },
  methods:{
    yearTabClick(i){
      this.yearIndex = i;
    },
    genData(){
      let zodiacStr = '鼠牛虎兔龙蛇马羊猴鸡狗猪';
      for(let i = 0,l = 11; i<l; i++){
        let data = {
          dateTime:'2024-3-15',
          dateNum:'031',
          codeList:[
            {
              code:37,
              zodiac:zodiacStr[i]
            },
            {
              code:37,
              zodiac:zodiacStr[i]
            },
            {
              code:37,
              zodiac:zodiacStr[i]
            },
            {
              code:37,
              zodiac:zodiacStr[i]
            },
            {
              code:37,
              zodiac:zodiacStr[i]
            },
            {
              code:37,
              zodiac:zodiacStr[i]
            }
          ],
          specialNo:{
            code:37,
            zodiac:'鼠'
          },
          zodiac:'猴',
          ballColor:'绿波',
        };
        this.historyDataList.push(data);
      }
    }
  },
  components:{
    Header,
    Footer,
    Banner,
  }
}
</script>


<style scoped>
.pager{
  width:7.5rem;
  margin:0 auto;
}
.year-list-w{
  display:flex;
}
.y-l-item{
  width:33.33%;
  line-height:1.26rem;
  position:relative;
  font-size:.38rem;
  text-align: center;
  font-weight:bold;
  color:#7F7F7F;
}
.y-l-item:before{
  content:' ';
  position:absolute;
  background:#7F7F7F;
  width:.02rem;
  height: 91%;
  right: 0;
  top:.08rem;
}
.y-l-item.curr::after{
  content:' ';
  width: 96%;
  height:.12rem;
  position:absolute;
  left:50%;
  transform:translateX(-50%);
  bottom:0;
  background-color:#6FA3A8;
}
.y-l-item:last-child:before{
  display:none;
}

.history-title{
  background: #BEDACC;
  color: #65746C;
  margin: .08rem auto 0 auto;
  width: 98.6%;
  font-size:.31rem;
  text-align: center;
  line-height:.78rem;
}
.history-head{
  background:#7EB69A;
  height:.4rem;
  font-size:.16rem !important;
  font-weight:bold;
  color:#070A09 !important;
}
.history-row{
  width:98.6%;
  margin:0 auto;
  display:flex;
  color:#005489;
  font-size:.14rem;
}
.history-row > div{
  width:.6rem;
  display: flex;
  justify-content:center;
  align-items: center;
  border-right:1px solid #BFBFBF;
}
.history-row > div:last-child{
  border-right:none;
}
.history-row .zhengma{
  width:3.33rem;
  border-right:none;
  padding-top:.06rem;
}
.history-row .zhengma > div{
  width:16.6%;
  display:flex;
  justify-content: center;
  align-items:center;
  flex-direction: column;
}
.history-row .date-time{
  width:1.2rem;
}
.history-row .tema{
  width:.92rem;
  justify-content:flex-end;
  padding-right:.08rem;
}
.history-row .tema .data-ceil{
  flex-direction:column;
  padding-top:.05rem;
  align-items:center;
  display:flex;
}
.history-row .tema .data-ceil .bonus-code{
  position:relative;
}
.history-row .tema .data-ceil .bonus-code .plus-flag{
  position:absolute;
  right:.53rem;
  font-size:.31rem;
  color:#9A9A9B;
}
.history-row .bonus-zodiac{
  font-weight:bold;
  font-size:.16rem;
  margin-top:.07rem;
  margin-bottom:.06rem;
}
.history-row .bonus-code{
  width:.44rem;
  height:.44rem;
  margin-top:.12rem;
  display:flex;
  justify-content: center;
  align-items: center;
  background-size:100% 100%;
  font-weight:bold;
  font-size:.18rem;
  color:#090909;
}
.history-row .bonus-code.red{
  background-image:url(../images/n-bg-red.png)
}
.history-row .bonus-code.green{
  background-image:url(../images/n-bg-green.png)
}
.history-row .bonus-code.blue{
  background-image:url(../images/n-bg-blue.png)
}
</style>