import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RecordView from '../views/RecordView.vue'
import GalleryView from '../views/GalleryView.vue'
import IntroduceView from '../views/IntroduceView.vue'
import HotNewsView from '../views/HotNewsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path:'/record',
    name:'record',
    component:RecordView
  },
  {
    path:'/gallery',
    name:'gallery',
    component:GalleryView
  },
  {
    path:'/introduce',
    name:'introduce',
    component:IntroduceView
  },
  {
    path:'/hotnews',
    name:'hotnews',
    component:HotNewsView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
