<template>
  <footer>
    <div class="f-r-item">
      <img class="footer-icon" src="../images/footer-icon.png" alt="">
      <div class="tail-change-btn" :class="{hide:isHideFooterNav}" @click="tailClickChange"></div>
    </div>
    <nav :class="{hide:isHideFooterNav}">
      <div class="f-r-item" v-for="item of footerItemList" @click="footerItemClick(item)">
        {{item.footerTitle}}
      </div>
    </nav>
    <div class="des">
      免责声明：本站内容仅供参考与交流，不进行任何现金交易行为。<br />
      本网站不承担由于内容的合法性所引起的一切争议和法律责任。<br />
      CopyRight 2024台湾星海彩 &nbsp;版权所有All Rights Reserverd
    </div>
  </footer>
</template>

<script>
export default{
  data(){
    return{
      isHideFooterNav:0,
      footerItemList:[
        {
          footerTitle:'首页',
          path:'',
          scrollTop:0
        },
        {
          footerTitle:'开奖记录',
          path:'record',
          scrollTop:400
        },
        {
          footerTitle:'正版图库',
          path:'gallery',
          scrollTop:825
        },
        {
          footerTitle:'彩票介绍',
          path:'introduce',
          scrollTop:1702
        },
        {
          footerTitle:'新闻资讯',
          path:'hotnews',
          scrollTop:2578
        },
        {
          footerTitle:'开奖公告',
          path:'',
          scrollTop:3895
        },
        {
          footerTitle:'五行号码',
          path:'',
          scrollTop:4703
        },
        {
          footerTitle:'生肖属性',
          path:'',
          scrollTop:5827
        },
        {
          footerTitle:'波色对照',
          path:'',
          scrollTop:6459
        }
      ],
    }
  },
  methods:{
    tailClickChange(){
      this.isHideFooterNav = this.isHideFooterNav ? 0 : 1;
    },
    footerItemClick(item){
      let scrollTop = item.scrollTop;
      this.$router.push('/')
      document.documentElement.scrollTop = this.ratioPxByWindow(scrollTop);
    },
    ratioPxByWindow(value){
      const standard = 750;
      return innerWidth * value / standard;
    }
  },
}
</script>

<style scoped>
  footer{
    background:#000000;
    padding-top:.6rem;
    color:white;
    padding-bottom:.88rem;
  }
  .footer-icon{
    width:2.68rem;
  }
  .tail-change-btn{
    display:block;
    content:' ';
    width:.24rem;
    height:.24rem;
    border:.02rem solid white;
    border-right:none;
    border-bottom:none;
    transform:rotateZ(45deg);
    margin-right:.1rem;
    transition:transform .3s;
  }
  .tail-change-btn.hide{
    transform:rotateZ(225deg)
  }
  .f-r-item{
    width:86%;
    height:auto;
    margin:auto;
    border-bottom:1px solid white;
    display:flex;
    justify-content:space-between;
    align-items: flex-end;
  }
  footer nav{
    overflow:hidden;
    height:4.05rem;
    transition:height .3s;
  }
  footer nav.hide{
    height:.01rem;
  }
  footer nav .f-r-item{
    font-size:.22rem;
    line-height:.44rem;
  }
  footer .des{
    width:86%;
    margin:.3rem auto 0 auto;
    color:#666666;
    font-size:.22rem;
  }
  footer > .f-r-item{
    padding:0 0 .2rem 0;
  }
</style>