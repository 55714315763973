<template>
  <div class="pager">
    <Header />
    <div class="banner">
      <div class="b-title">
        News
      </div>
      <div class="b-title secondary">
        新闻资讯
      </div>
    </div>
    <div class="site-map">
      <div><span>首页</span>&gt;<span>新闻资讯</span></div>
    </div>
    <div class="h-n-item-w" v-for="n of 20">
      <div class="n-i-pic">
        图片
      </div>
      <div>
        <div class="news-content">
          洛杉矶的宽带数据卡圣诞快乐圣诞快乐打算离开就开了多少可是到了扣税的肯定是看待世界科技看了大家快乐发角度上考虑看到
        </div>
        <div class="news-date">
          2024年1月27日
        </div>
      </div>
    </div>
    <div>
      分页组件
    </div>
    <Footer/>
  </div>
</template>
<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import Banner from '../components/Banner.vue'

export default {
  data(){
    return{}
  },
  mounted(){
  },
  methods:{
  },
  components:{
    Header,
    Footer,
    Banner,
  }
}
</script>


<style scoped>
.pager{
  width:7.5rem;
  margin:0 auto;
}
.banner{
  width:100%;
  height:2.8rem;
  background-color:rgb(77, 178, 167);
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.b-title{
  color:white;
  text-align: center;
  font-size:.32rem;
  font-weight:bold;
}
.b-title.secondary{
  margin-top:.06rem;
}
.site-map{
  font-size:.23rem;
  line-height: .4rem;
  padding-left:.72rem;
  color: #8d8d8d;
  margin:.1rem 0;
}
.h-n-item-w{
  width:89%;
  margin:auto;
  height:auto;
  display:flex;
  border-bottom:1px solid rgb(192, 192, 192);
  font-size:.22rem;
}
.news-content{
  width:4.18rem;
  height:1.65rem;
  padding:.1rem 0 0 .22rem;
}
.news-date{
  text-align: right;
}
.n-i-pic{
  background-color:gray;
  width:2.49rem;
  height: 2.02rem;
}
</style>