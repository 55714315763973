<template>
  <div class="pager">
    <Header />
    <Banner title="正版图库" seTitle="绝版机密" invok='gallery' />

    <div class="year-list-w">
      <div class="y-l-item" :class="{curr:yearIndex == i}" v-for="(tabItem,i) of tabItemList" @click="yearTabClick(i)">
        {{tabItem.tab}}
      </div>
    </div>

    <div class="head-wrap">
      <div>绝版机密</div>
      <div>Out of print secret</div>
    </div>

    <div class="gallery-l-wrapper">
      <div>
        图片
      </div>
      <div>
        图片
      </div>
      <div>
        图片
      </div>
      <div>
        图片
      </div>
    </div>

    <div style="height:.6rem;">
      分页组件
    </div>
    <Footer/>
  </div>
</template>
<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import Banner from '../components/Banner.vue'

export default {
  data(){
    return{
      tabItemList:[
        {
          tab:'热门图库',
        },
        {
          tab:'推荐图库'
        }
      ],
      yearIndex:0,
      historyDataList:[],
    }
  },
  mounted(){
    this.genData();
  },
  methods:{
    yearTabClick(i){
      this.yearIndex = i;
    },
    genData(){
      let zodiacStr = '鼠牛虎兔龙蛇马羊猴鸡狗猪';
      for(let i = 0,l = 11; i<l; i++){
        let data = {
          dateTime:'2024-3-15',
          dateNum:'031',
          codeList:[
            {
              code:37,
              zodiac:zodiacStr[i]
            },
            {
              code:37,
              zodiac:zodiacStr[i]
            },
            {
              code:37,
              zodiac:zodiacStr[i]
            },
            {
              code:37,
              zodiac:zodiacStr[i]
            },
            {
              code:37,
              zodiac:zodiacStr[i]
            },
            {
              code:37,
              zodiac:zodiacStr[i]
            }
          ],
          specialNo:{
            code:37,
            zodiac:'鼠'
          },
          zodiac:'猴',
          ballColor:'绿波',
        };
        this.historyDataList.push(data);
      }
    }
  },
  components:{
    Header,
    Footer,
    Banner,
  }
}
</script>


<style scoped>
.pager{
  width:7.5rem;
  margin:0 auto;
}
.year-list-w{
  display:flex;
  justify-content: center;
}
.y-l-item{
  width:33.33%;
  line-height:1.26rem;
  position:relative;
  font-size:.38rem;
  text-align: center;
  font-weight:bold;
  color:#7F7F7F;
}
.y-l-item:before{
  content:' ';
  position:absolute;
  background:#7F7F7F;
  width:.02rem;
  height: 91%;
  right: 0;
  top:.08rem;
}
.y-l-item.curr::after{
  content:' ';
  width: 96%;
  height:.12rem;
  position:absolute;
  left:50%;
  transform:translateX(-50%);
  bottom:0;
  background-color:#6FA3A8;
}
.y-l-item:last-child:before{
  display:none;
}
.head-wrap{
  width:100%;
  height:auto;
  text-align: center;
  padding-top:.3rem;
}
.head-wrap > div:nth-child(1){
  font-size:.36rem;
  font-weight: bold;
}
.head-wrap > div:nth-child(2){
  font-size:.3rem;
  color:#828282;
  margin-top:.05rem;
}
.gallery-l-wrapper{
  width:100%;
  display:flex;
  flex-wrap:wrap;
}
.gallery-l-wrapper > div{
  width:3.44rem;
  height:3.68rem;
  border:1px solid #BDBDBD;
  flex-shrink:0;
  margin-left:.2rem;
  margin-top:.26rem;
}
</style>