<template>
  <div class="banner">
    <img class="gallery-bg" v-if="invok == 'gallery'" src="../images/gallery-banner.png" alt="">
    <img class="gallery-bg" v-else-if="invok == 'introduce'" src="../images/introduce-banner.png" alt="">
    <img class="bg" v-else src="../images/introduce-bg.png" alt="">

    <div class="m-layer"></div>
    <div class="before">
      <div>{{title}}</div>
      <div>{{seTitle}}</div>
    </div>
  </div>
</template>

<script>
export default{
  props:{
    title:String,
    seTitle:String,
    invok:String,
  }
}
</script>

<style scoped>
.banner{
  width:100%;
  height:3.68rem;
  position:relative;
  overflow:hidden;
}
.banner .bg{
  width:100%;
  position:absolute;
  top:-.47rem;
}
.banner .gallery-bg{
  height:3.68rem;
  position:absolute;
}
.banner .before{
  width:100%;
  height:100%;
  position:absolute;
  left:0;
  top:0;
  padding-top:.88rem;
}
.banner .m-layer{
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,.14)
}
.banner .before > div{
  color:white;
  text-align: center;
}
.banner .before > div:first-child{
  font-size:.56rem;
  font-weight:bold;
}
.banner .before > div:nth-child(2){
  font-size:.34rem;
}
</style>